import Card from "components/card";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { truncateText } from "utils/App.utils";
import { MdOutlineEdit } from "react-icons/md";
import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import ToggleSwitch from "../components/toggleSwitch";
import { publishRating } from "actions/rating/RatingActions";

const getStatusClasses = (status) => {
  switch (status) {
    case "pending":
      return "bg-yellow-200 text-yellow-800";
    case "rejected":
      return "bg-red-200 text-red-800";
    case "approved":
      return "bg-green-200 text-green-800";
    case "deleted":
      return "bg-gray-200 text-gray-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

const renderRatingStars = (rating = 0) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <svg
          key={i}
          class={`${i !== 0 ? "ms-1" : ""}  h-4 w-4 text-yellow-300`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      );
    } else {
      stars.push(
        <svg
          key={i}
          class={`${
            i !== 0 ? "ms-1" : ""
          } h-4 w-4 text-gray-300 dark:text-gray-500`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      );
    }
  }
  return <div className="flex items-center">{stars}</div>;
};

function RatingsTableCard({ ratings, children, loading }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePublish = (ratingId) => {
    // console.log(ratingId);
    dispatch(publishRating(ratingId));
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="h-10 w-9" />
      {"Customer"}
    </div>,
    <div className="flex items-center gap-2">
      <div className="h-10 w-9" />
      {"Merchant"}
    </div>,
    "Message",
    "Rating",
    "Status",
    "Published",
    "Actions",
  ];

  const records = ratings.map((rating) => ({
    1: (
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => {
          if (rating?.customer_id?.id) {
            navigate(`/customer/profile/${rating?.customer_id?.id}`);
          }
        }}
      >
        <div className="h-10 w-9">
          <img
            className="h-9 w-9 rounded-full"
            src={rating?.customer_id?.profile_pic ?? ProfileIcon}
            alt=""
          />
        </div>
        {rating?.customer_id?.name?.trim()?.split(" ").join("\u00A0") ?? "N/A"}
      </div>
    ),
    2: (
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => navigate(`/merchants/update/${rating.merchant_id.id}`)}
      >
        <div className=" h-10  w-9">
          <img
            className="h-9 w-9 rounded-full"
            src={rating?.merchant_id?.logo || ProfileIcon}
            alt=""
          />
        </div>
        {rating?.merchant_id?.name?.trim()?.split(" ").join("\u00A0") ?? "N/A"}
      </div>
    ),
    message: truncateText(rating?.message, 10),
    ratings: renderRatingStars(rating?.ratings),
    rating_status: (
      <span
        className={`rounded-full  px-2 py-1 text-xs font-semibold ${getStatusClasses(
          rating.status
        )}`}
      >
        {rating.status.toUpperCase()}
      </span>
    ),
    publish: (
      <ToggleSwitch
        isEnabled={rating?.is_published ?? false}
        handleChange={() => {
          handlePublish(rating.id);
        }}
      />
    ),
    actions: (
      <div className="flex items-center gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.CUSTOMER_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/rating/update/${rating.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
      </div>
    ),
  }));
  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Customer Reviews"} />
        {children}
        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default RatingsTableCard;
