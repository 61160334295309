
const DeliveryOption = ({ merchantData, onChangeFormData }) => {

  const onChangeDeliveryOption = (name, value) => {
    // Create a new copy of delivery_options instead of mutating state
    const updatedDeliveryOptions = {
      ...merchantData.delivery_options,
      [name]: value,
    };

    onChangeFormData({
      name: "delivery_options",
      value: updatedDeliveryOptions,
    });
  };

  return (
    <div className="flex flex-col justify-between gap-3">
      {/* <div className="flex w-full items-center gap-20">
        <label className="mb-1 block text-base font-medium text-gray-800">
          Delivery Option Available?
        </label>
      </div> */}

      {/* Radius, Fee and Time */}
      {merchantData.delivery_options.available ? (
        <div className="flex flex-col justify-between gap-2 sm:flex-row">
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Radius
            </label>
            <input
              onFocus={(e) => e.target.select()} // Ensures whole value is selected when focused
              onChange={(e) =>
                onChangeDeliveryOption(e.target.name, Number(e.target.value))
              }
              required
              placeholder="Enter Radius"
              type="number"
              name="deliveryRadius"
              value={merchantData.delivery_options.deliveryRadius}
              className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
            />
          </div>
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Fee
            </label>
            <input
              onFocus={(e) => e.target.select()} // Ensures whole value is selected when focused
              onChange={(e) =>
                onChangeDeliveryOption(e.target.name, Number(e.target.value))
              }
              required
              placeholder="Enter Fee"
              type="number"
              name="deliveryFee"
              value={merchantData.delivery_options.deliveryFee}
              className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
            />
          </div>
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Time
            </label>
            <input
              onFocus={(e) => e.target.select()} // Ensures whole value is selected when focused
              onChange={(e) =>
                onChangeDeliveryOption(e.target.name, Number(e.target.value))
              }
              required
              placeholder="Enter Time"
              type="number"
              name="deliveryTime"
              value={merchantData.delivery_options.deliveryTime}
              className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DeliveryOption;
