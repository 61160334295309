import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  qrCodesCount: 0,
  qrCodes: [],
  qrCode: null,
  loading: false,
};

export const merchantQrCodesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_MERCHANT_QR_CODES:
      return {
        ...state,
        qrCodesCount: payload?.meta?.total_documents || 0,
        qrCodes: payload.qrCodes,
        loading: false,
      };
    case ActionTypes.UPDATE_MERCHANT_QR_CODES:
      return {
        ...state,
        qrCodesCount: state.qrCodesCount + 1,
        qrCodes: [...state.qrCodes, payload],
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_QR_CODE:
      return {
        ...state,
        qrCode: payload.code,
        loading: false,
      };
    case ActionTypes.UPDATE_MERCHANT_QR_CODE:
      return {
        ...state,
        qrCodes: state.qrCodes.map((code) =>
          code.id === payload.id ? payload : code
        ),
        loading: false,
      };
    case ActionTypes.DELETE_MERCHANT_QR_CODE:
      return {
        ...state,
        qrCodesCount: state.qrCodesCount - 1,
        qrCodes: state.qrCodes.filter((qrCode) => qrCode.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_QR_CODE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_MERCHANT_QR_CODE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
