import Card from "components/card";
import Loader from "components/loaderSpinner/Loader";
import {
  PERMISSION_ACTION_TYPES,
  PERSMISSIONS_TYPES,
} from "constants/role.constants";
import { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { formatLocalTimeDate } from "utils/App.utils";
import { hasPermission } from "utils/permissions";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import SortTable from "../components/sortTable";
import ImageModal from "../components/imageModal";
import { useNavigate } from "react-router-dom";
import { createMerchantQrCode } from "actions/merchantQrCodes/merchantQrCodes.action";
import { updateMerchantQrCode } from "actions/merchantQrCodes/merchantQrCodes.action";

const initialReceiptData = {
  label: "",
};

const CodesTableCard = ({ codes, merchant_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.merchantQrCodes);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [codeData, setCodeData] = useState(initialReceiptData);

  const onChangeFormData = (name, value) => {
    setCodeData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCreateClick = () => {
    setShowCreateModal(true);
  };

  const handleEditClick = (code) => {
    if (code) {
      setCodeData(code);
    }
    setShowEditModal(true);
  };

  const handleCancelEdit = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setCodeData(initialReceiptData);
  };

  const handleSubmitCreate = () => {
    const { label } = codeData;

    let payload = { label, merchant_id };
    dispatch(createMerchantQrCode(payload));
    setShowCreateModal(false);
    setCodeData(initialReceiptData);
  };
  const handleSubmitUpdate = () => {
    const { label } = codeData;

    let payload = { label };
    dispatch(updateMerchantQrCode(codeData.id, payload));
    setShowEditModal(false);
    setCodeData(initialReceiptData);
  };

  const tableHeaders = [
    { value: "Label", sort: false },
    { value: "Thumbnail", sort: false },
    { value: "Created At", sort: true },
    { value: "Actions", sort: false },
  ];

  const records = codes.map((code) => ({
    label: { value: code.label, sortKey: code.label },
    thumbnail: {
      value: (
        <div className="flex items-center justify-center">
          <div className=" h-10 w-10">
            <img
              className="h-10 w-10 cursor-pointer rounded-md"
              src={code.path}
              alt=""
              onClick={() => {
                setImageUrl(code.path);
              }}
            />
          </div>
        </div>
      ),
      sortKey: null,
    },
    date: {
      value: code.created_at ? formatLocalTimeDate(code.created_at) : "",
      sortKey: code.created_at,
    },
    actions: {
      value: (
        <div className="flex items-center justify-center gap-2">
          <MdOutlineEdit
            onClick={() => handleEditClick(code)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        </div>
      ),
      sortKey: null,
    },
  }));

  return (
    <div>
      {showCreateModal ? (
        <ConfirmationModal
          onBack={handleCancelEdit}
          onConfirm={handleSubmitCreate}
          confirmBtnText="OK"
          confirmBtnType="success"
        >
          <div className="flex flex-col gap-2">
            {loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="flex w-full items-center justify-center text-xl font-semibold ">
                  Create QR Code
                </div>
                <div className="flex flex-col justify-between gap-1">
                  {/* label */}
                  <>
                    <label
                      for="content"
                      className="block place-self-start text-base font-medium text-navy-800"
                    >
                      Label
                    </label>
                    <input
                      type="text"
                      name="label"
                      placeholder="Label"
                      value={codeData.label}
                      onChange={(e) =>
                        onChangeFormData("label", e.target.value)
                      }
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                    />
                  </>
                </div>
              </>
            )}
          </div>
        </ConfirmationModal>
      ) : null}
      {showEditModal ? (
        <ConfirmationModal
          onBack={handleCancelEdit}
          onConfirm={handleSubmitUpdate}
          confirmBtnText="OK"
          confirmBtnType="success"
        >
          <div className="flex flex-col gap-2">
            {loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="flex w-full items-center justify-center text-xl font-semibold ">
                  Edit QR Code
                </div>
                <div className="flex flex-col justify-between gap-1">
                  {/* label */}
                  <>
                    <label
                      for="content"
                      className="block place-self-start text-base font-medium text-navy-800"
                    >
                      Label
                    </label>
                    <input
                      type="text"
                      name="label"
                      placeholder="Label"
                      value={codeData.label}
                      onChange={(e) =>
                        onChangeFormData("label", e.target.value)
                      }
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                    />
                  </>
                </div>
              </>
            )}
          </div>
        </ConfirmationModal>
      ) : null}

      {imageUrl ? (
        <ImageModal onBack={() => setImageUrl(null)}>
          <div className="flex h-96 w-96 items-center justify-center">
            <img
              className="size-80 cursor-pointer "
              src={imageUrl ?? ""}
              alt=""
            />
          </div>
        </ImageModal>
      ) : null}

      <Card extra={" bg-white"}>
        <CardHeader
          label={"Merchant QR Codes"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Back",
                    onClick: () => navigate(-1),
                    variant: "dark",
                  },
                  {
                    text: "Add New",
                    onClick: () => handleCreateClick(),
                    variant: "primary",
                  },
                ]
              : null
          }
        />
        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default CodesTableCard;
