import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import MerchantQrCodesService from "../../services/merchantQrCodes/MerchantQrCodesService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_QR_CODE_LOADING,
    payload: loading,
  });
};

export const setMerchantQrCodes = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_QR_CODES,
    payload: data,
  });
};
export const updateMerchantQrCodes = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MERCHANT_QR_CODES,
    payload: data,
  });
};

export const setMerchantQrCode = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_QR_CODE,
    payload: data,
  });
};

export const updateMerchantQrCodeLabel = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MERCHANT_QR_CODE,
    payload: data,
  });
};

export const removeMerchantQrCode = (code_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_MERCHANT_QR_CODE,
    payload: code_id,
  });
};

export const resetMerchantQrCodeState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_MERCHANT_QR_CODE_STATE,
  });
};

export const getMerchantQrCodes =
  (page, limit, merchantId) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantQrCodesService.getMerchantQrCodes(
        page,
        limit,
        merchantId
      );
      if (response.status == 1) {
        dispatch(setMerchantQrCodes(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load merchant QR Code",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getMerchantQrCode = (code_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantQrCodesService.getMerchantQrCode(code_id);
    if (response.data && response.status == 1) {
      dispatch(setMerchantQrCode(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant QR Code",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createMerchantQrCode = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantQrCodesService.createMerchantQrCode(payload);
    if (response.status == 1) {
      dispatch(updateMerchantQrCodes(response.data));
      dispatch(
        setAlert(
          response.message ?? "merchant QR Code created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate(`/merchantQrCodes`);
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create merchant QR Code",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateMerchantQrCode =
  (code_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantQrCodesService.updateMerchantQrCode(
        code_id,
        payload
      );
      if (response.status == 1) {
        dispatch(updateMerchantQrCodeLabel(response.data));
        dispatch(
          setAlert(
            response.message ?? "merchant QR Code updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate(`/merchantQrCodes`);
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant QR Code",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

// export const deleteMerchantQrCode =
//   (code_id, navigate) => async (dispatch) => {
//     try {
//       dispatch(setLoading(true));
//       const response =
//         await MerchantQrCodesService.deleteMerchantQrCode(
//           code_id
//         );
//       if (response.status == 1) {
//         dispatch(
//           setAlert(
//             response.message ?? "merchant QR Code deleted successfully",
//             AlertTypes.SUCCESS
//           )
//         );
//         dispatch(removeMerchantQrCode(code_id));
//         if (!navigate) return;
//         return navigate("/merchant");
//       }
//       dispatch(
//         setAlert(
//           response.message ?? "failed to delete merchant QR Code",
//           AlertTypes.ERROR
//         )
//       );
//     } catch (error) {
//       dispatch(setAlert("server error", AlertTypes.ERROR));
//     } finally {
//       dispatch(setLoading(false));
//     }
//   };
