import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getMerchantQrCodes = (page = 1, limit = 50, merchantId) => {
  const merchantID = merchantId ? `&merchant_id=${merchantId}` : "";
  return RestClient.Get(
    `${API_URL.GET_MERCHANT_QR_CODES}?page=${page}&limit=${limit}${merchantID}`
  );
};

const getMerchantQrCode = (code_id) => {
  return RestClient.Get(`${API_URL.GET_MERCHANT_QR_CODE}/${code_id}`);
};

const createMerchantQrCode = (payload) => {
  return RestClient.Post(API_URL.CREATE_MERCHANT_QR_CODE, payload);
};

const updateMerchantQrCode = (code_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MERCHANT_QR_CODE}/${code_id}`,
    payload
  );
};

const exportedObject = {
  getMerchantQrCodes,
  getMerchantQrCode,
  createMerchantQrCode,
  updateMerchantQrCode,
};

export default exportedObject;
