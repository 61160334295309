import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getRatings = (page = 1, limit = 50, rating_status) => {
  const status = rating_status ? `&status=${rating_status}` : "";
  return RestClient.Get(
    `${API_URL.GET_RATINGS}?page=${page}&limit=${limit}${status}`
  );
};
const getRating = (ratingId) => {
  return RestClient.Get(`${API_URL.GET_RATING}/${ratingId}`);
};
const updateRating = (ratingId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_RATING}/${ratingId}`, payload);
};
const publishRating = (ratingId) => {
  return RestClient.Put(`${API_URL.PUBLISH_RATING}/${ratingId}`);
};

const exportedObject = {
  getRatings,
  getRating,
  updateRating,
  publishRating,
};

export default exportedObject;
