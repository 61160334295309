import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import { getMerchantQrCodes } from "actions/merchantQrCodes/merchantQrCodes.action";
import { useParams } from "react-router-dom";
import CodesTableCard from "./codes-table-card";

const QrCodes = () => {
  const { merchant_id } = useParams();
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { qrCodesCount, qrCodes, qrCode, loading } = useSelector(
    (state) => state.merchantQrCodes
  );

  useEffect(() => {
    dispatch(getMerchantQrCodes(page, limit, merchant_id));
  }, [page]);

  return (
    <div className="h-full w-full">
      <CodesTableCard codes={qrCodes} merchant_id={merchant_id} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={qrCodesCount}
      />
    </div>
  );
};

export default QrCodes;
