import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  ratingsCount: 0,
  ratings: [],
  rating: null,
  loading: false,
};

export const RatingsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_RATINGS:
      return {
        ...state,
        ratingsCount: payload?.meta?.total_documents || 0,
        ratings: payload.ratings,
        loading: false,
      };
    case ActionTypes.UPDATE_RATING_PUBLISH_FLAG:
      return {
        ...state,
        ratings: state.ratings.map((rating) =>
          rating.id === payload.rating.id
            ? { ...rating, is_published: payload.rating.is_published }
            : rating
        ),
        loading: false,
      };
    case ActionTypes.SET_RATING:
      return {
        ...state,
        rating: payload.rating,
        loading: false,
      };
    case ActionTypes.UPDATE_SET_RATING:
      return {
        ...state,
        ratings: state.ratings.map((rating) =>
          rating.id === payload.rating.id
            ? { ...rating, status: payload.rating.status }
            : rating
        ),
        loading: false,
      };
    case ActionTypes.SET_RATING_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_RATING_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
